<template>
  <main class="rfis-container">
    <div class="rfi-item" v-for="rfi in rfis" :key="rfi">
      <p class="-stb">{{ rfi.ref }}</p>
      <div class="rfi-u-wrapper">
        <DynaBadge
          :picturePath="rfi.img"
          :firstName="rfi.name"
          :lastName="rfi.lastName"
        />
        <div class="rfi-u-details">
          <div class="-stb">{{ rfi.name }}</div>
          <div class="-xstr">{{ rfi.mail }}</div>
        </div>
      </div>
      <p class="-xstb">{{ rfi.date }}</p>
    </div>
  </main>
</template>

<script>
import DynaBadge from "../../utils/DynaBadge.vue";
export default {
  components: {
    DynaBadge,
  },
  data() {
    return {
      rfis: [
        {
          ref: "R.1.01",
          name: "Henry Tillbot",
          lastName: "Tillbot",
          mail: "htillbot@brooks.com",
          date: "Today",
          img: require("../../../assets/temp/users/jimmy_hopkins.png"),
        },
        {
          ref: "R.0.99",
          name: "Antony Bell",
          lastName: "Bell",
          mail: "abell@gradient.net",
          date: "Sep 09, 2023",
          img: require("../../../assets/temp/users/mark_thompson.png"),
        },
        {
          ref: "R.0.85",
          name: "Henry Tillbot",
          lastName: "Tillbot",
          mail: "htillbot@brooks.com",
          date: "Sep 11, 2023",
          img: require("../../../assets/temp/users/jimmy_hopkins.png"),
        },
        {
          ref: "R.0.54",
          name: "Mandy Oliver",
          lastName: "Oliver",
          mail: "general@rsp.com",
          date: "Sep 15, 2023",
          img: require("../../../assets/temp/users/sophia_holland.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.rfis-container {
  width: 100%;
  height: 100%;
  max-height: 240px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.rfi-item {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border-bottom: 1px solid var(--gray6);
  gap: 1rem;
}
.rfi-item:last-child {
  border-bottom: none;
}
.rfi-item > p:first-child {
  width: 20%;
}
.rfi-item > p:last-child {
  width: 30%;
  text-align: right;
  padding-right: 1rem;
}
.rfi-u-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 8px;
}

@media screen and(max-width: 480px) {
  .rfis-container {
    max-height: 480px;
  }
}
</style>
