<template>
  <section class="pie-container">
    <apexchart
      type="donut"
      width="380"
      height="240"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>

<script>
export default {
  props: ["title", "count", "pvalue", "rating", "dataValues", "hideNotes"],
  data() {
    return {
      series: [32, 58, 10],
      chartOptions: {
        chart: {
          type: "donut",
        },
        colors: [
          "var(--ongoing-color)",
          "var(--completed-color)",
          "var(--awaiting-verification-color)",
        ],
        labels: ["Ongoing", "Completed", "Awaiting Verification"],
        legend: {
          position: "bottom",
        },

        plotOptions: {
          pie: {
            donut: {
              size: "40%",
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.pie-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
