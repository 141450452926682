<template>
  <main class="thumbnail-wrapper">
    <div class="thumbnail">
      <ThumbnailDone />
    </div>
    <p class="-ltb">Fantastic! No delays in sight.</p>
  </main>
</template>

<script>
import ThumbnailDone from "../../dynamicThumbnails/ThumbnailDone.vue";
export default {
  components: { ThumbnailDone },
};
</script>

<style scoped>
.thumbnail-wrapper {
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.thumbnail {
  width: 100%;
  height: 100%;
  scale: 1.25;
  opacity: 0.4;
}
.thumbnail + p {
  position: absolute;
  opacity: 0;
  animation: fade-in 0.5s ease-in-out;
  animation-delay: 800ms;
  animation-fill-mode: forwards;
  color: var(--primary);
  user-select: none;
}

@media screen and (max-width: 1023px) {
  .thumbnail {
    scale: 1;
  }
}
</style>
