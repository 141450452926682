<template>
  <main class="upcoming-wrapper">
    <section
      v-for="item in getDeadlinesByDate"
      :key="item"
      class="tracked-items-wrapper"
    >
      <div class="tracked-items">
        <p class="-stb">{{ item.date }}</p>
        <div
          v-for="deadline in item.deadlines"
          :key="deadline"
          class="tracked-items-content"
        >
          <p class="-xstb">{{ deadline.title }}</p>
          <p class="-xstb">{{ deadline.definition }}</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  props: ["deadlines"],
  data() {
    return {};
  },

  computed: {
    getDates() {
      const dates = [...new Set(this.deadlines.map((item) => item.date))];

      return dates;
    },

    getDeadlinesByDate() {
      const deadlinesByDate = new Map();

      for (const item of this.deadlines) {
        if (!deadlinesByDate.has(item.date)) {
          deadlinesByDate.set(item.date, {
            date: item.date,
            deadlines: [],
          });
        }

        deadlinesByDate.get(item.date).deadlines.push({
          title: item.title,
          definition: item.definition,
        });
      }

      return Array.from(deadlinesByDate.values());
    },
  },
};
</script>

<style scoped>
.upcoming-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-block: 8px;
  gap: 8px;
  overflow: auto;
}
.tracked-items-wrapper {
  width: max-content;
}
.tracked-items {
  display: flex;
  flex-flow: column;
}
.tracked-items > p:first-child {
  color: var(--white1);
  padding-block: 2px;
  padding-inline: 4px;
  background: var(--secondary);
  width: 52px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 8px;
}
.tracked-items-content {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}
.tracked-items-content > p:first-child::before {
  content: "\2B9E";
  font-size: 10px;
  margin-right: 4px;
}
.tracked-items-content > p:last-child {
  color: var(--gray2);
}
p {
  text-align: left;
}
</style>
