<template>
  <main class="chart-container kpi-wrapper">
    <div class="kpi-item" v-for="kpi in kpis" :key="kpi">
      <KpiComponent :kpi="kpi" />
    </div>
  </main>
</template>

<script>
import KpiComponent from "./KpiComponent.vue";
export default {
  components: { KpiComponent },
  props: ["kpis"],
};
</script>

<style scoped>
.kpi-wrapper {
  height: 100%;
  --kpi-item-width: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.kpi-item {
  width: var(--kpi-item-width);

  height: max-content;
  padding: 8px;
}

@media screen and (max-width: 800px) {
  .gauge-box {
    display: flex;
  }
}
</style>
