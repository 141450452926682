<template>
  <main class="chart-container">
    <section class="msight-container">
      <iframe
        :src="msightLink"
        allowfullscreen
        class="msight"
        height="212"
      ></iframe>
      <span class="close" @click="closeSight"></span>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    closeSight() {
      this.$store.dispatch("loadIFC", false);
    },
  },
  computed: {
    ...mapGetters(["msightLink"]),
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 8px;
  --myColor: black;
  background: white;
}

.msight {
  width: 100%;
  border: none;
  border-radius: 4px;
}
</style>
