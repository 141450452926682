<template>
  <main class="chart-container">
    <apexchart
      type="radialBar"
      height="150"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <p class="-stb">{{ kpi.name }}</p>
  </main>
</template>

<script>
export default {
  props: ["kpi"],

  data() {
    return {
      series: [this.kpi.series],
      chartOptions: {
        chart: {
          type: "radialBar",
          // offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#f9f5ff",
              strokeWidth: "100",
              margin: 5, // margin is in pixels
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "22px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -20,
          },
        },
        colors: ["#086ff4"],
        fill: {
          type: "solid",
          colors: [
            function ({ value }) {
              if (value < 50) {
                return "#D9534F";
              } else if (value < 80) {
                return "#F4CA77";
              } else if (value <= 100) {
                return "#48B975";
              } else {
                return "#086ff4";
              }
            },
          ],
        },
        labels: ["Average Results"],
      },
    };
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  flex-flow: column;
  grid-column: 1 / span 3;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: inherit !important;
}
</style>
