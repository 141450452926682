<template>
  <main class="deadlines-tracker-wrapper">
    <div class="calendar-wrapper">
      <VCalendar />
    </div>

    <section class="info-box">
      <div class="delayed-container">
        <DelayedSection />
      </div>
      <div class="upcoming-container">
        <UpcomingList :deadlines="deadlines" />
      </div>
    </section>
  </main>
</template>

<script>
import VCalendar from "../VCalendar.vue";
import DelayedSection from "./DelayedSection.vue";
import UpcomingList from "./UpcomingList.vue";

export default {
  components: { VCalendar, UpcomingList, DelayedSection },
  props: ["deadlines"],
};
</script>

<style scoped>
.deadlines-tracker-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  --calendar-width: 252px;
}
.calendar-wrapper {
  position: absolute;
  z-index: 1;
  min-width: var(--calendar-width);
  max-width: var(--calendar-width);
  top: -37px;
  left: -1px;
}

.info-box {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: calc(var(--calendar-width) + 8px);
}
.upcoming-container {
  width: 70%;
  height: 280px;
  padding-inline: 4px;
}
.delayed-container {
  width: 30%;
  height: 280px;
  padding-inline: 4px;
}

@media screen and (max-width: 1023px) {
  .deadlines-tracker-wrapper {
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .calendar-wrapper {
    position: relative !important;
    align-self: center;
    top: 0;
    padding-top: 1rem;
  }
  .info-box {
    flex-flow: column;
    padding-inline: 1rem;
  }
  .upcoming-container {
    width: 100%;
    height: auto;
    max-height: 420px;
    overflow: auto;
  }
  .delayed-container {
    width: 100%;
  }
}
</style>
