<template>
  <main class="chart-container">
    <apexchart
      type="radar"
      width="380"
      height="190"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </main>
</template>

<script>
export default {
  props: ["stages"],

  data() {
    return {
      success: "#12b76a",
      fail: "#eb5757",
      //THIS IS THE CHART OBJECT
      series: [
        {
          name: "Achievable",
          data: [80, 50, 82, 50, 100, 30, 80, 90],
        },
        {
          name: "Current",
          data: [80, 30, 70, 60, 80, 20, 80, 75],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "radar",
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radar: {
            size: 70,
            offsetX: 0,
            offsetY: 10,
            polygons: {
              strokeColor: "#e8e8e8",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
          labels: {
            show: true,
            style: {
              colors: ["#a8a8a8"],
            },
          },
        },
        yaxis: { show: false },
      },
    };
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.chart-container > .vue-apexcharts {
  scale: 0.84;
}
</style>
