<template>
  <main class="v-calendar-wrapper" @mousewheel.passive="handleMouseWheel">
    <Calendar ref="calendar" :attributes="attributes" />
  </main>
</template>

<script>
export default {
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: {
            color: "gray",
            fillMode: "", //	Color fill option: solid (default), light, outline
            // style: {
            //   backgroundColor: "var(--secondary)",
            // },
          },
          dot: {
            style: {
              backgroundColor: "var(--gray2)",
            },
          }, //Boolean, String, Object
          // bar: false, //Boolean, String, Object
          content: "", // Boolean, String, Object
          popover: {
            //Only objects allowed
            label: "No deadlines today",
            visibility: "focus",
          },
          //Custom data object for later access, if needed
          customData: {},
          // We also need some dates to know where to display the attribute
          // We use a single date here, but it could also be an array of dates,
          //  a date range or a complex date pattern.
          dates: new Date(),
          //Ooptionally provide dates to exclude
          excludeDates: null,
          // Think of `order` like `z-index`
          order: 0,
          contentClass: "test",
        },
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          popover: {
            description: "Take Noah to basketball practice.",
            isComplete: false,
            dates: { weekdays: 6 },
            color: "red",
          },
          dates: { start: new Date(2023, 8, 18), end: new Date(2023, 8, 23) },
          popover: {
            label: "Concrete Pouring days",
          },
        },
      ],
    };
  },
  methods: {
    handleMouseWheel(event) {
      const calendar = this.$refs.calendar;
      event.deltaY > 0 ? calendar.move(1) : calendar.move(-1);
    },
  },
};
</script>

<style scoped>
.v-calendar-wrapper {
  width: 100%;
  height: 100%;
}
.vc-container {
  width: 100%;
  border-color: transparent;
  background-color: transparent;
}
:deep(.vc-header) {
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  padding: 0;
}
:deep(.vc-title) {
  color: white;
}
:deep(.vc-arrows-container) {
  padding: 0;
  height: 36px;
  display: flex;
  align-items: center;
  padding-inline: 4px;
}
:deep(.vc-arrow:hover) {
  background: none;
  scale: 1.1;
  transition: 200ms;
  color: white;
}
:deep(.vc-arrow:active) {
  scale: 0.9;
}
:deep(.vc-day.is-not-in-month > span) {
  opacity: 0.2;
}
:deep(.vc-day.is-not-in-month:hover > span) {
  opacity: 0.4;
}

@media screen and (max-width: 1023px) {
  :deep(.vc-title) {
    color: var(--primary-color);
  }
  :deep(.vc-arrow:hover) {
    color: var(--primary-color);
  }
}
</style>
