<template>
  <main class="content-page">
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">Unit Progress</p>
      </div>
      <UnitProgress />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">Lifecycle Benchmark</p>
      </div>
      <LifeCycleBenchmark />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">Key Performance Indicators</p>
      </div>
      <KpiList :kpis="kpis" />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">Deadlines Tracker</p>
      </div>
      <DeadlinesTracker :deadlines="deadlines" />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">Tasks Control</p>
      </div>
      <Donut />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">Documents Control</p>
      </div>
      <Polar />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">RFI's</p>
      </div>
      <Rfi />
    </section>
    <section class="charts-container">
      <div class="chart-header">
        <p class="-stb">MSight</p>
      </div>
      <MiniMSViewVue />
    </section>
  </main>
</template>

<script>
import UnitProgress from "../../../components/charts/dashboard/UnitProgress.vue";
import LifeCycleBenchmark from "../../../components/charts/dashboard/LifeCycleBenchmark.vue";
import KpiList from "../../../components/charts/dashboard/kpis/KpiList.vue";
import DeadlinesTracker from "../../../components/charts/dashboard/DeadlinesTracker.vue";
import Donut from "../../../components/charts/dashboard/Donut.vue";
import Polar from "../../../components/charts/dashboard/PolarChart.vue";
import MiniMSViewVue from "../../../components/charts/dashboard/MiniMSView.vue";
import Rfi from "../../../components/charts/dashboard/RfiWrapper.vue";
import DelayedSection from "../../../components/charts/dashboard/DelayedSection.vue";
export default {
  props: ["stages"],
  components: {
    UnitProgress,
    LifeCycleBenchmark,
    KpiList,
    DeadlinesTracker,
    Donut,
    Polar,
    MiniMSViewVue,
    Rfi,
    DelayedSection,
  },
  data() {
    return {
      deadlines: [
        {
          date: "21/09",
          title: "T.2.050",
          definition: "Prepare and issue final project brief.",
        },
        {
          date: "21/09",
          title: "T.2.270",
          definition: "Prepare concept design for structural design.",
        },
        {
          date: "24/09",
          title: "T.2.360",
          definition:
            "Assist lead design with preparation of stage design programme.",
        },
        {
          date: "30/09",
          title: "T.2.370",
          definition: "Prepare construction strategy",
        },
        {
          date: "30/09",
          title: "T.2.380",
          definition: "Develop health and safety strategy.",
        },
        {
          date: "30/09",
          title: "D.2.001",
          definition: "Concept design federated BIM model.",
        },
      ],
      kpis: [
        {
          series: 60,
          name: "Coordinator",
        },
        {
          series: 130,
          name: "Planning",
        },
        {
          series: 100,
          name: "Budgetary",
        },
        {
          series: 20,
          name: "Commissioning",
        },
        {
          series: 70,
          name: "Quality",
        },
      ],
      mounted() {
        console.warn(this.$store.state);
      },
    };
  },
};
</script>

<style scoped>
.content-page {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  /* height: calc(100% - 4px); */
  gap: 8px;
}

.charts-container {
  grid-column: 1 / span 2;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: white;
  border-radius: 4px;
  opacity: 0;
  transform: translateY(-50%);
  animation: slide-in-top-menu 600ms ease-in-out;
  animation-fill-mode: forwards;
}
.charts-container > .chart-header {
  width: 100%;
  min-height: 36px;
  color: var(--white1);
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
  background-size: 500% auto;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.charts-container:nth-child(1) {
  min-width: 380px;
  height: 224px;
}
.charts-container:nth-child(2) {
  grid-column: 3 / span 2;
  animation-delay: 50ms;
  height: 224px;
}
.charts-container:nth-child(3) {
  grid-column: 5 / span 4;
  animation-delay: 100ms;
  height: 224px;
}
.charts-container:nth-child(4) {
  grid-column: 1 / span 8;
  height: max-content;
  animation-delay: 150ms;
}
.charts-container:nth-child(5) {
  grid-column: 1 / span 2;
  animation-delay: 200ms;
}
.charts-container:nth-child(6) {
  grid-column: 3 / span 2;
  animation-delay: 250ms;
}
.charts-container:nth-child(7) {
  grid-column: 5 / span 2;
  animation-delay: 300ms;
}
.charts-container:nth-child(8) {
  grid-column: 7 / span 2;
  animation-delay: 350ms;
}

.grid-chart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
:deep(.chart-container) {
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: var(--white1);
}
@media screen and (max-width: 1200px) {
  .content-page {
    display: flex;
    flex-flow: column;
  }
}
</style>
