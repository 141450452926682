<template>
  <main class="unit-progress-wrapper">
    <apexchart
      type="area"
      width="380"
      height="180"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </main>
</template>

<script>
export default {
  props: ["title", "count", "pvalue", "rating", "dataValues", "hideNotes"],
  data() {
    return {
      success: "#12b76a",
      fail: "#eb5757",
      //THIS IS THE CHART OBJECT
      series: [
        {
          name: "Estimated",
          data: [10, 20, 30, 40, 45, 60, 65, 70, 75, 85, 90, 100],
        },
        {
          name: "Real",
          data: [12, 23, 27, 45, 50, 52, 58, 80, 82, 85, 100, 100],
        },
      ],
      chartOptions: {
        chart: {
          height: "auto",
          type: "area",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
          },
        },
        colors: ["#9fd1ff", "#086ff4"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: [
            "2023-01-01T10:00:00.000Z",
            "2023-02-01T10:00:00.000Z",
            "2023-03-01T10:00:00.000Z",
            "2023-04-01T10:00:00.000Z",
            "2023-05-01T10:00:00.000Z",
            "2023-06-01T10:00:00.000Z",
            "2023-07-01T10:00:00.000Z",
            "2023-08-01T10:00:00.000Z",
            "2023-09-01T10:00:00.000Z",
            "2023-10-01T10:00:00.000Z",
            "2023-11-01T10:00:00.000Z",
            "2023-12-01T10:00:00.000Z",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.unit-progress-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>
