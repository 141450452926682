<template>
  <section class="pie-container">
    <apexchart
      type="polarArea"
      width="380"
      height="240"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>

<script>
export default {
  data() {
    return {
      series: [60, 50, 40],
      chartOptions: {
        chart: {
          type: "polarArea",
        },
        colors: [
          "var(--ongoing-color)",
          "var(--completed-color)",
          "var(--awaiting-verification-color)",
        ],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
        },
        labels: [
          "Documents for approval",
          "Documents approved",
          "Awaiting Characterization",
        ],

        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 1,
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
        yaxis: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 1900,
            options: {
              chart: {
                width: 300,
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.pie-container {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  position: relative;
}
.pie-title {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 20px;
}
.pie-p {
  background-color: var(--secondary);
  color: white;
  font-weight: bold;
  border-radius: 4px;
  padding: 0 10px;
}
.pie-info p {
  display: inline;
  padding: 0.33rem;
  background: var(--highlight-color);
  border-radius: 0.2rem;
  color: var(--text-color);
  font-family: "SulphurPoint-Bold";
}
</style>
